import React, { useState, useEffect } from "react";

import styles from "./one.module.css";
import { EchoDotTop } from "../../components/echodottop";
import { loadFont } from "../../libs/font";
import { loadImage } from "../../libs/image";
import { wait } from "../../libs/util";

const convos = [
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
  "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
];

export function TemplateOne(props) {
  const [activeConvos, setActiveConvos] = useState(convos);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      loadFont("Open Sans", 300),
      loadFont("Open Sans", 400),
      loadFont("Open Sans", 600),
      loadFont("Bookerly", 400),
      loadImage(
        "https://images-na.ssl-images-amazon.com/images/I/61ljEE5F8YL.png"
      ),
      wait(1000),
    ]).then(() => {
      setLoading(false);
    });
  }, []);
  return (
    !loading && (
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Alexa Cookbook</h1>
          <span style={{ flex: "1 1 100%" }} />
          <img
            className={styles.logo}
            src="https://images-na.ssl-images-amazon.com/images/I/61ljEE5F8YL.png"
          />
        </div>
        <div className={styles.middle}>
          <div className={styles.leftColumn}>
            <div className={styles.body}>
              {/* <ul className={styles.conversations}>
                {activeConvos.map((conv) => {
                  return <li>{conv}</li>;
                })}
              </ul> */}
              <div className={styles.openCommand}>
                “Alexa, open alexa cookbook”
              </div>
            </div>
          </div>
          <div className={styles.rightColumn}>
            <EchoDotTop className={styles.dotone} />
          </div>
        </div>
        <div className={styles.footer}></div>
      </div>
    )
  );
}
